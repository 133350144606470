import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbAlertModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';


// ANCHOR NGPRIME
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    InputSwitchModule,
    ButtonModule,
    DropdownModule,
    NgSelectModule,
    NgbAlertModule,
    NgbDatepickerModule,
  ],
  exports: [
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    InputSwitchModule,
    ButtonModule,
    DropdownModule,
    NgSelectModule,
    NgbAlertModule,
    NgbDatepickerModule,
  ]
})
export class SharedModule { }
